'use client'

import { useEffect, useState } from 'react'

export default function DebugGrid({}) {
	const [guidesOn, setGuidesOn] = useState(false)

	useEffect(() => {
		function listenForShortcut(event: KeyboardEvent) {
			if (event.code == "KeyG") {
				if (guidesOn) {
					setGuidesOn(false)
				} else {
					setGuidesOn(true)
				}
			}
		}
		window.addEventListener('keyup', listenForShortcut, false)
		return () => window.removeEventListener('keyup', listenForShortcut, false)
	}, [guidesOn])

	if (!guidesOn) return null

	return (
		<div className="fixed z-[999999] inset-0 site-grid [&>div]:bg-[rgba(255,0,0,0.07)] pointer-events-none">
			<div />
			<div />
			<div />
			<div />
			<div />
			<div />
			<div />
			<div />
			<div />
			<div />
			<div />
			<div />
		</div>
	)
}
