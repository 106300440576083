'use client'

import { useEffect, useRef } from 'react'
import HereAmpersand from '@/components/logos/HereAmpersand'
import HereLogo from '@/components/logos/HereLogo'
import useApp from '@/lib/store'
import { cn } from '@/lib/utils'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { mapRange } from '@/lib/utils'

interface HeaderProps {
	navItems?: any
}

const getLogoOpacity = (scrollProg: number, isHome: boolean) => {
	if (!isHome) return 1
	const from = 30
	const to = 35
	if (scrollProg > to) return 1
	return mapRange(scrollProg, from, to, 0, 1)
}

export default function Header({ navItems }: HeaderProps) {
	const { isNavOpen, setIsNavOpen } = useApp()
	const pathname = usePathname()

	const activeNavItem = navItems.find((item) => item?.url == pathname) || null

	const activeNavItemClass = 'text-black underline underline-offset-[0.25em]'

	const isHome = pathname == '/'
	// Fetch initial state
	const scrollProgRef = useRef(useApp.getState().introScrollProgress)
	// Connect to the store on mount, disconnect on unmount, catch state-changes in a reference
	useEffect(() => {
		useApp.subscribe((state) => (scrollProgRef.current = state.introScrollProgress))
	}, [])

	return (
		<>
			<div className="site-wrapper">
				<div
					className={`fixed z-900 py-4 md:py-5 px-site-margin`}
					style={{
						opacity: getLogoOpacity(scrollProgRef.current, isHome)
					}}>
					<Link href="/" onClick={() => setIsNavOpen(false)}>
						<HereLogo className={`block h-5 md:h-[20px] w-auto text-black`} size="small" />
					</Link>
				</div>
			</div>

			<div className={`fixed top-0 left-0 z-800 w-full bg-white/90 backdrop-blur-[6px]`}>
				<div className={`site-wrapper px-site-margin`}>
					<div className="flex flex-wrap items-center justify-end py-4 md:py-5">
						<div className="w-auto flex sm:hidden items-baseline gap-6">
							{activeNavItem && <div className="leading-90 -translate-y-[0.05em] hidden">{activeNavItem?.title}</div>}
							<button onClick={() => setIsNavOpen(!isNavOpen)} className="inline-block">
								<HereAmpersand
									className={cn('block h-5 md:h-7 w-auto text-black transition-all duration-300 overflow-hidden', {
										'rotate-180': isNavOpen,
										'rotate-0': !isNavOpen
									})}
								/>
							</button>
						</div>

						<ul
							className="hidden sm:flex gap-11"
							style={{
								transform: 'translateY(-2px)'
							}}>
							{navItems.map((item) => (
								<li key={item.title} className="style-nav-item">
									<Link
										href={item?.url}
										onClick={() => setIsNavOpen(false)}
										className={pathname == item?.url ? activeNavItemClass : ''}>
										{item.title}
									</Link>
								</li>
							))}
						</ul>
					</div>

					<div
						className={cn('sm:hidden w-full sm:w-auto grid transition-all duration-[400ms] overflow-hidden', {
							'grid-rows-[1fr]': isNavOpen,
							'grid-rows-[0fr]': !isNavOpen
						})}>
						<div className="overflow-hidden">
							<ul className={`flex flex-col gap-4 py-7 text-right`}>
								{navItems.map((item, index) => (
									<li
										key={item.title}
										className="style-nav-item transition-all"
										style={{
											opacity: isNavOpen ? 1 : 0,
											transform: isNavOpen ? 'translateY(0rem)' : 'translateY(-1rem)',
											// transitionEasing: 'cubic-bezier(0.19, 1, 0.22, 1)',
											transitionDuration: isNavOpen ? `400ms` : '400ms', //? This might need tweaking
											transitionDelay: isNavOpen ? `${20 + (index + 1) * 50}ms` : '0ms' //? This might need tweaking
										}}>
										<Link
											href={item?.url}
											onClick={() => setIsNavOpen(false)}
											className={pathname == item?.url ? activeNavItemClass : ''}>
											{item.title}
										</Link>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
