'use client'

import {suspend} from 'suspend-react'
import {LiveQueryProvider} from 'next-sanity/preview'

const UniqueKey = Symbol('lib/sanity.client')

export default function PreviewProvider({
	children,
	token,
}: {
	children: React.ReactNode
	token?: string
}) {
	const {client} = suspend(() => import('@/sanity/lib/client'), [UniqueKey])
	if (!token) throw new TypeError('Missing token')
	return (
		<>
			<LiveQueryProvider
				client={client}
				token={token}
				// logger={console}
			>
				{children}
			</LiveQueryProvider>
			<div className="fixed bottom-4 left-4 z-[99999] inline-flex items-center gap-2 rounded-full bgWite border-solid border border-black/50 p-2 font-system text-[14px] leading-100 text-black hover:text-black">
				<div className="icon">
					<svg className='block w-5 h-5 animate-pulse' stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path><path d="M12 12m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0"></path><path d="M15.9 20.11l0 .01"></path><path d="M19.04 17.61l0 .01"></path><path d="M20.77 14l0 .01"></path><path d="M20.77 10l0 .01"></path><path d="M19.04 6.39l0 .01"></path><path d="M15.9 3.89l0 .01"></path><path d="M12 3l0 .01"></path><path d="M8.1 3.89l0 .01"></path><path d="M4.96 6.39l0 .01"></path><path d="M3.23 10l0 .01"></path><path d="M3.23 14l0 .01"></path><path d="M4.96 17.61l0 .01"></path><path d="M8.1 20.11l0 .01"></path><path d="M12 21l0 .01"></path></svg>
				</div>
				<a href="/api/exit-preview" className="relative pr-1 text-black">
					Exit Live Preview
				</a>
			</div>
		</>
)
}