import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-sanity@8.5.3_@sanity+client@6.15.11_@sanity+icons@2.11.7_react@18.2.0__@sanity+types@3.3_tgpdokxofuyln4yvgndaepjl3i/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_@babel+core@7.24.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_@babel+core@7.24.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/(site)/layout.tsx\",\"import\":\"Roboto_Mono\",\"arguments\":[{\"weight\":[\"400\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"variable\":\"--font-temp\"}],\"variableName\":\"roboto_mono\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_@babel+core@7.24.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/(site)/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../fonts/untitled-sans-regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"}],\"variable\":\"--font-untitled\"}],\"variableName\":\"untitled\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_@babel+core@7.24.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/(site)/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../fonts/ITCNewBaskervilleRoman_normal_normal.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../fonts/ITCNewBaskervilleItalic_italic_normal.woff2\",\"weight\":\"400\",\"style\":\"italic\"}],\"variable\":\"--font-new-baskerville\"}],\"variableName\":\"newBaskerville\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_@babel+core@7.24.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/(site)/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../fonts/BaskervilleDisplayPTRegular_normal_normal.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../fonts/BaskervilleDisplayPTItalic_italic_normal.woff2\",\"weight\":\"400\",\"style\":\"italic\"}],\"variable\":\"--font-baskerville-display\"}],\"variableName\":\"baskervilleDisplay\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Analytics.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/DebugGrid.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Footer.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Header.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/KeyboardShortcuts.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/PreloadResources.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/StyledJsxRegister.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/css/global.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/sanity/components/PreviewProvider.tsx")