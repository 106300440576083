'use client'

import RichText from '@/components/RichText'
import HereLogoBcorp from '@/components/logos/HereLogoBcorp'
import useApp from '@/lib/store'
import { cn } from '@/lib/utils'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

interface FooterProps {
	site?: any
	latestArticles?: any
}

export default function Footer({ site, latestArticles }: FooterProps) {
	const { setIsNavOpen } = useApp()
	const pathname = usePathname()

	return (
		<>
			<Tickertape items={site?.tickertapeItems} />

			<div className={'w-full py-11 bg-blackBg text-white text-small leading-140'}>
				<div className={`site-wrapper site-grid px-site-margin`}>
					<div className="col-span-full md:col-span-9 footerFull:col-span-10 grid gap-gutter grid-cols-12 md:grid-cols-9 footerFull:grid-cols-10">
						<div className="flex flex-col items-start md:justify-start col-span-5 sm:col-span-4 md:col-span-4">
							<HereLogoBcorp className="flex-none block w-full xs:w-[75%] md:w-[50%] md:pr-4 h-auto text-white mb-6 md:mb-12" />
							{site?.bcorpStatement && (
								<div className="block style-caption-small md:max-w-[320px] text-balance [&_a]:underlinexxx">
									<RichText content={site?.bcorpStatement} />
								</div>
							)}
						</div>
						
						{/* SITE NAV LINKS */}
						<ul className="col-span-4 col-start-9 md:col-span-2 md:col-start-8 footerFull:col-start-9 flex flex-col items-end md:items-start gap-6">
							{site?.navItems?.map((item) => (
								<li key={item?.title} className="style-nav-item">
									<Link
										href={item?.url}
										onClick={() => setIsNavOpen(false)}
										className={cn(' text-white hover:text-blue transition', {
											'underline underline-offset-4': pathname == item?.url
										})}>
										{item?.title}
									</Link>
								</li>
							))}
						</ul>
					</div>
						
					{/* CONTACT DETAILS */}
					<div className="col-span-full md:col-span-2 flex items-end md:items-start justify-between mt-12 md:mt-0">
						<div className="style-caption-small contacts flex flex-col gap-6">
							{site?.contacts?.emailNewBusiness && (
								<div>
									<h3>New Business</h3>
									<p>
										<a
											href={`mailto:${site?.contacts?.emailNewBusiness}`}
											className="text-white hover:text-blue transition">
											{site?.contacts?.emailNewBusiness}
										</a>
									</p>
								</div>
							)}
							{site?.contacts?.emailPress && (
								<div>
									<h3>Press & PR</h3>
									<p>
										<a href={`mailto:${site?.contacts?.emailPress}`} className="text-white hover:text-blue transition">
											{site?.contacts?.emailPress}
										</a>
									</p>
								</div>
							)}
							{site?.contacts?.emailJobs && (
								<div>
									<h3>Jobs & Internships</h3>
									<p>
										<a href={`mailto:${site?.contacts?.emailJobs}`} className="text-white hover:text-blue transition">
											{site?.contacts?.emailJobs}
										</a>
									</p>
								</div>
							)}
							{site?.contacts?.phone && (
								<div>
									<h3>Telephone</h3>
									<p>
										<a href={`tel:${site?.contacts?.phone}`} className="text-white hover:text-blue transition">
											{site?.contacts?.phone}
										</a>
									</p>
								</div>
							)}
							{/* SOCIAL LINKS */}
							<ul className="socials flex items-center gap-4">
								{site?.socialLinks?.twitter && (
									<li>
										<a
											aria-label="Follow us on X"
											href={site?.socialLinks?.twitter}
											className="text-white hover:text-blue transition"
											target="_blank">
											<svg
												className="w-4 h-auto fill-current"
												role="img"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg">
												<title>X</title>
												<path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z" />
											</svg>
										</a>
									</li>
								)}
								{site?.socialLinks?.instagram && (
									<li>
										<a
											aria-label="Follow us on Instagram"
											href={site?.socialLinks?.instagram}
											className="text-white hover:text-blue transition"
											target="_blank">
											<svg
												className="w-4 h-auto fill-current"
												role="img"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg">
												<title>Instagram</title>
												<path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
											</svg>
										</a>
									</li>
								)}
								{site?.socialLinks?.linkedin && (
									<li>
										<a
											aria-label="Follow us on LinkedIn"
											href={site?.socialLinks?.linkedin}
											className="text-white hover:text-blue transition"
											target="_blank">
											<svg
												className="w-4 h-auto fill-current -mt-[2px]"
												role="img"
												viewBox="0 0 20 20"
												xmlns="http://www.w3.org/2000/svg">
												<path
													d="m4.50001 20h-4.250014v-13.375h4.250014zm-2.12501-15.25c-1.375 0-2.375-1-2.375-2.375s1.125-2.375 2.375-2.375c1.375 0 2.375 1 2.375 2.375s-1 2.375-2.375 2.375zm17.625 15.25h-4.25v-7.25c0-2.125-.875-2.75-2.125-2.75s-2.5 1-2.5 2.875v7.125h-4.25v-13.375h4v1.875c.375-.875 1.875-2.25 4-2.25 2.375 0 4.875 1.375 4.875 5.5v8.25z"
													fill="currentColor"
												/>
											</svg>
										</a>
									</li>
								)}
							</ul>
						</div>
					</div>

					<div className="col-span-full md:text-rightxxx style-caption-small mt-12">
						© {new Date().getFullYear()} Here Design
					</div>
				</div>
			</div>
		</>
	)
}

function Tickertape({ items }) {
	if (!items || items?.length === 0) return null
	const linkClass = 'w-auto pr-16 pb-[2px] flex-none flex items-end text-white hover:text-blue transition gap-4'
	const titleClass = 'relative top-[0.2em] md:top-[0.15em] md:text-[1.15em]'
	return (
		<>
			<div className="tickertape w-full overflow-hidden bg-blackBg text-white py-4 md:py-7 border-solid border-b border-white">
				<div className="w-full flex items-center">
					<div className="tickertape__content w-auto flex-none flex items-center">
						{items?.map((item) => {
							const slugPrefix = item?.link?._type === 'article' ? '/journal' : '/work'
							const url = `${slugPrefix}/${item?.link?.slug?.current}`
							return (
								<Link key={`${JSON.stringify(item)}_1`} href={url} className={linkClass}>
									<div className={`style-h4 font-new-sans text-white before:content-['#']`}>{item?.tag?.toLowerCase()}</div>
									<div className={`style-body`}>
										<span className={titleClass}>
											{item?.title}
										</span>
									</div>
								</Link>
							)
						})}
					</div>
								<div className="tickertape__content w-auto flex-none flex items-center">
									{items?.map((item) => {
										const slugPrefix = item?.link?._type === 'article' ? '/journal' : '/work'
										const url = `${slugPrefix}/${item?.link?.slug?.current}`
										return (
											<Link key={`${JSON.stringify(item)}_1`} href={url} className={linkClass}>
												<div className={`style-h4 font-new-sans text-white before:content-['#']`}>{item?.tag?.toLowerCase()}</div>
												<div className={`style-body`}>
													<span className={titleClass}>
														{item?.title}
													</span>
												</div>
											</Link>
										)
									})}
								</div>
				</div>
			</div>
		</>
	)
}
