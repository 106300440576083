'use client'

import useApp from '@/lib/store'
import { FC, useEffect } from 'react'

interface KeyboardShortcutsProps {
}

const KeyboardShortcuts: FC<KeyboardShortcutsProps> = ({}) => {
  const {setIsNavOpen, setIsCartOpen, setIsCookieManagerOpen} = useApp()

	useEffect( () => {

		function listenForEscapeKey(event: KeyboardEvent) {
			const key = event.key
			if ( key == 'Escape' ) {
				setIsNavOpen(false)
				setIsCartOpen(false)
				setIsCookieManagerOpen(false)
			}
		}
		window.addEventListener('keyup', listenForEscapeKey, false)
		return () => window.removeEventListener('keyup', listenForEscapeKey, false)
  }, [])

	return null
}
export default KeyboardShortcuts
